<template>
	<div class="filter-share-box">
		<div class="container">
			<!-- 搜索框 -->
			<div class="filter-share-search-box">
				<!-- 输入框 -->
				<el-input v-model="search" class="filter-share-search" @keyup.enter.native="setSearch(search)">
					<template v-slot:append>
						<el-button icon="el-icon-search" class="filter-share-search-btn" @click="setSearch(search)">
						</el-button>
					</template>
				</el-input>
				<!-- 推荐 -->
				<ul class="filter-share-search-option">
					<li class="filter-share-search-option-title">
						大家都在搜：
					</li>
					<li v-for="item in filterHotwords" :key="item.id">
						<a href="javascript:;" @click="setSearch(item)">{{item}}</a>
					</li>
				</ul>
			</div>
			<!-- 筛选条件 -->
			<div class="filter-share-area-box">
				<span>省市区：</span>
				<!-- <area-cascader 
                    v-model="city"
                    type='text'
                    :level='1'
                    :data="$pcaa"
                    placeholder="请选择地区"
                    @change="handleArea"
                    class="filter-share-area-cascader">
                </area-cascader> -->
				<el-cascader :options="options" :props="{
                        value: 'label'
                    }" v-model="city" @change="handleArea" size="small" class="filter-share-cascader">
				</el-cascader>
			</div>
			<div class="filter-share-sift-box">
				<!-- 风格 -->
				<ul class="filter-share-sift" v-if="$route.name == 'clothes'">
					<li class="filter-style-title">风格</li>
					<li>
						<a href="javascript:;" :class="{'filter-active': sift_style == '0'}" @click="handleStyle('0')">
							全部
						</a>
					</li>
					<li v-for="item in filterStyle" :key="item.id">
						<a href="javascript:;" :class="{'filter-active': sift_style == item.id}"
							@click="handleStyle(item.id)">
							{{item.name}}
						</a>
					</li>
				</ul>
				<!-- 类型 -->
				<ul class="filter-share-sift" v-if="$route.name == 'field'">
					<li class="filter-style-title">类型</li>
					<li>
						<a href="javascript:;" :class="{'filter-active': sift_type == '0'}" @click="handleClazz('0')">
							全部
						</a>
					</li>
					<li v-for="item in filterClazz" :key="item.id">
						<a href="javascript:;" :class="{'filter-active': sift_type == item.id}"
							@click="handleClazz(item.id)">
							{{item.name}}
						</a>
					</li>
				</ul>
				<!-- 价格 -->
				<ul class="filter-share-sift">
					<li class="filter-price-title">价格</li>
					<li>
						<a href="javascript:;" :class="{'filter-active': sift_price == '0'}" @click="handlePrice('0')">
							全部
						</a>
					</li>
					<li v-for="item in filterPrice" :key="item.id">
						<a href="javascript:;" :class="{'filter-active': sift_price == item.id}"
							@click="handlePrice(item.id)">
							{{item.name}}
						</a>
					</li>
				</ul>
				<!-- 排序 -->
				<!-- <div class="filter-share-sort-box">
                    <el-button
                        type="text"
                        size="mini">
                        <span>价格</span>
                        <div class="sort-icon">
                            <i class="el-icon-caret-top"></i>
                            <i class="el-icon-caret-bottom sort-active"></i>
                        </div>
                    </el-button>
                </div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		regionDataPlus
	} from "element-china-area-data";

	export default {
		data() {
			let search = sessionStorage.getItem("search") || "",
				sift_style = sessionStorage.getItem("sift_style") || "0",
				sift_type = sessionStorage.getItem("sift_type") || "0",
				sift_price = sessionStorage.getItem("sift_price") || "0",
				sift_area = sessionStorage.getItem("sift_area") || "",
				city = sift_area ? sift_area.split("/") : [];
			return {
				search,
				sift_style,
				sift_type,
				sift_price,
				sift_area,
				city,
				options: regionDataPlus,
				Searchparameters: []
			}
		},
		props: {
			filterHotwords: Array,
			filterStyle: Array,
			filterClazz: Array,
			filterPrice: Array,
			remoteMethods: Function
		},
		methods: {
			// 模糊搜索 切换
			searchResult(arg) {
				if(this.filterStyle){
					let list = [];
				this.filterStyle.map(function(item) {
					if (item.name.indexOf(arg) > -1) {
						return list.push(item)
					}
				})
				this.Searchparameters = list[0].id
				}else{
					let list = [];
					this.filterClazz.map(function(item) {
						if (item.name.indexOf(arg) > -1) {
							return list.push(item)
						}
					})
					this.Searchparameters = list[0].id
				}
				
			},

			//搜索框
			setSearch(arg) {
				this.searchResult(arg);
				this.search = arg;
				this.handleClazz(this.Searchparameters)
				this.handleStyle(this.Searchparameters)
				// sessionStorage.setItem("search", arg);
				// this.remoteMethods({
				// 	search: '',
				// 	area:  this.sift_area,
				// 	style: this.Searchparameters,
				// 	clazz: this.Searchparameters,
				// 	price: this.sift_price
				// })

			},
			//城市
			handleArea(arg) {
				let val = arg.join("/");
				this.sift_area = val;
				sessionStorage.setItem("sift_area", val);
				this.remoteMethods({
					search: '',
					area: val,
					style: this.sift_style,
					clazz: this.sift_type,
					price: this.sift_price
				})
			},
			//风格
			handleStyle(arg) {
				// console.log("类型",arg)
				this.sift_style = arg;
				sessionStorage.setItem("sift_style", arg);
				this.remoteMethods({
					search: '',
					area: this.sift_area,
					style: arg,
					clazz: this.sift_type,
					price: this.sift_price
				})
			},
			//空间
			handleClazz(arg) {
				// console.log("类型",arg)
				this.sift_type = arg;
				sessionStorage.setItem("sift_type", arg);
				this.remoteMethods({
					search: '',
					area: this.sift_area,
					style: this.sift_style,
					clazz: arg,
					price: this.sift_price
				})
			},
			//价格 全部
			handlePrice(arg) {
				this.sift_price = arg;
				sessionStorage.setItem("sift_price", arg);
				this.remoteMethods({
					search:'' ,
					area: this.sift_area,
					style: this.sift_style,
					clazz: this.sift_type,
					price: arg
				})
			},
		},
	}
</script>

<style scoped>
	.filter-share-box {
		background-color: #fff;
	}

	/* 搜索框部分 */
	.filter-share-search-box {
		text-align: center;
		padding: 50px 0;
	}

	/* 搜索框 */
	.filter-share-search {
		width: 50%;
		margin-bottom: 10px;
	}

	.filter-share-search>>>.el-input__inner {
		border-right: 0;
		border-width: 2px;
		border-color: #8d9afc;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	.filter-share-search>>>.el-input-group__append {
		border-color: #8d9afc;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		background-color: #8d9afc !important;
		color: #fff;
	}

	/* 搜索选项 */
	.filter-share-search-option {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.filter-share-search-option>li>a {
		margin-left: 10px;
		color: #8d9afc;
	}

	.filter-share-search-option-title {
		color: #909399;
	}

	/* 筛选条件 */
	.filter-share-area-box {
		padding-bottom: 30px;
	}

	.filter-share-area-box>span {
		display: inline-block;
		vertical-align: middle;
		padding: 0 10px;
	}

	.filter-share-cascader {
		width: 300px;
	}

	.filter-share-cascader>>>input {
		border-radius: 15px;
	}

	/* .filter-share-area-cascader{
    display: inline-block;
    vertical-align: middle;
}
.filter-share-area-cascader>>>.area-select{
    width: 300px;
    border-radius: 15px;
}
.filter-share-area-cascader>>>.area-selected-trigger{
    padding: 6px 20px 6px 12px;
} */

	.filter-share-sift-box {
		margin-bottom: 20px;
	}

	.filter-share-sift {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	/* 选项 */
	.filter-share-sift>li>a {
		display: block;
		margin-left: 10px;
		padding: 5px 15px;
		border-radius: 15px;
		color: #303133;
	}

	.filter-share-sift>li>a.filter-active,
	.filter-share-sift>li>a:hover {
		background-color: #E6A23C;
		color: #fff;
	}

	/* 标题 */
	.filter-style-title,
	.filter-type-title,
	.filter-price-title {
		padding: 10px 15px;
		background: #8d9afc;
		color: #fff;
	}

	/* 风格 */
	.filter-style-title {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	/* 价格 */
	.filter-price-title {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	/* 排序 */
	.filter-share-sort-box {
		text-align: right;
	}

	.sort-icon {
		display: inline-flex;
		vertical-align: middle;
		flex-direction: column;
		margin-left: 5px;
	}

	.sort-icon>i {
		color: #C0C4CC;
	}

	.sort-icon>i.sort-active {
		color: #8d9afc
	}
</style>
